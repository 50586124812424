/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { find, get, isEmpty } from 'lodash';

import { Button } from 'app/common/components';
import {
  useStateProvinceRegionSelectValues,
  useFormatMessage
} from 'app/common/hooks';

import messages from './UserAddress.messages';

const UserAddress = props => {
  const {
    address,
    addressName,
    onSelected = address => {},
    isSelected,
    isDefault = false
  } = props;
  const allStateProvinceRegions = useStateProvinceRegionSelectValues();
  const formatMessage = useFormatMessage();

  const relevantStateProvinceRegions = allStateProvinceRegions[address.country];
  const stateProvinceRegion = get(
    find(relevantStateProvinceRegions, ['value', address.stateProvinceRegion]),
    'value'
  );

  return (
    <Button
      type="button"
      className={cx(
        'relative w-full mb-4 p-4 text-gray-900 text-left border border-solid rounded shadow focus:outline-none focus:shadow-outline',
        {
          'bg-primary-100 border-primary-500 hover:bg-primary-200': isSelected,
          'bg-white border-transparent hover:bg-gray-200': !isSelected
        }
      )}
      onClick={() => onSelected(address)}
    >
      {isDefault && (
        <span className="absolute top-4 right-4 mb-1 px-2 py-1 bg-green-200 font-semibold uppercase tracking-wide text-xs text-green-800 rounded">
          {formatMessage(messages.defaultAddress)}
        </span>
      )}
      {!isEmpty(addressName) && (
        <div className="mb-1 font-bold">{addressName}</div>
      )}
      <div className="text-sm leading-tight text-gray-700">
        {!address.fleetAddress && (
          <div>
            {address.fullName || `${address.firstName} ${address.lastName}`}
          </div>
        )}
        {address.fleetAddress && <div>Fleet: {address.fleetAccount}</div>}
        <div>{address.addressLine1}</div>
        {!!address.addressLine2 && <div>{address.addressLine2}</div>}
        {!!address.addressLine3 && <div>{address.addressLine3}</div>}
        <div>{`${!!address.city ? address.city : ''} ${stateProvinceRegion} ${
          !!address.postalCode ? address.postalCode : ''
        }`}</div>
        {!!address.country && <div>{address.country}</div>}
        {!!address.phonePrimary && (
          <div>{address.phonePrimary.phoneNumber}</div>
        )}
      </div>
    </Button>
  );
};

UserAddress.propTypes = {
  /** The address to display **/
  address: PropTypes.shape({
    fullName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    companyName: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    stateProvinceRegion: PropTypes.string,
    country: PropTypes.string,
    postalCode: PropTypes.string,
    phonePrimary: PropTypes.shape({ phoneNumber: PropTypes.string }),
    isActive: PropTypes.bool
  }).isRequired,
  /** Name of the address */
  addressName: PropTypes.string,
  /** Function to trigger selection of the address **/
  onSelected: PropTypes.func,
  /** Is this address currently selected **/
  isSelected: PropTypes.bool
};
export default UserAddress;
export { UserAddress };
