import React, { useContext } from 'react';
import { PaymentGatewayType } from 'app/common/constants';
import { getPaymentRequestValues } from 'app/common/hooks/payment/paymentUtil.js';
import { getCartHeaders } from 'app/common/utils/RequestUtils';
import {
  useCartInfo,
  useFormatMessage,
  useHistory,
  useModifyCartRequest
} from 'app/common/hooks/index.js';
import { CartContext } from 'app/common/contexts';
import PaymentForm from '../PaymentForm/index.js';
import {
  DecoratedField,
  SecondaryButton
} from 'app/common/components/index.js';
import { get } from 'lodash';
import { PAYMENT_INFO_PATH } from '../../../../CheckoutPaths.js';
import messages from './AuroraPayPaymentInfoStage.messages.js';
import { default as messages2 } from 'app/checkout/components/CheckoutLayout/components/FulfillmentInfoWithOptions/FulfillmentInfoWithOptions.messages';
import { CheckoutContext } from 'app/checkout/contexts/index.js';
import { AuroraPayImage } from 'app/checkout/utils/PaymentMethodUtil.js';

const AuroraPayPaymentInfoStage = ({ active = false, completed = false }) => {
  return active || !completed ? (
    <AuroraPayPaymentInfoEditable active={active} completed={completed} />
  ) : (
    <AuroraPayPaymentInfoReadOnly />
  );
};

const AuroraPayPaymentInfoEditable = ({ active = false, completed }) => {
  const { cart, setCart } = useContext(CartContext);
  const {
    checkoutOperations: { baseUrl }
  } = useCartInfo();
  const { sendCallback: updateCartInfo } = useModifyCartRequest(
    `${baseUrl}/${cart.id}`,
    null,
    false
  );

  const cartHeaders = getCartHeaders(cart.version);

  const handleSubmit = async (values, actions, addlInfo) => {
    const { attributes, paymentType, gatewayType, paymentMethodProperties } =
      values;

    const updatedCart = await updateCartInfo({
      method: 'patch',
      data: {
        attributes: {
          PAY_IN_STORE: false
        },
        purchaseOrderNumber: values.purchaseOrderNumber
      },
      headers: cartHeaders
    });
    setCart(updatedCart);

    const { sendPaymentRequest, method } = addlInfo;
    const amounts = getPaymentRequestValues(updatedCart);

    const request = {
      method,
      attributes: {
        PAY_IN_STORE: false,
        VENDOR_CODE: values.dealerLocation,
        CUSTOMER_CODE: values.fleetLocationId,
        cart: JSON.stringify(updatedCart),
        ...attributes
      },
      type: paymentType,
      gatewayType,
      paymentMethodProperties,
      ...amounts
    };
    return await sendPaymentRequest(request);
  };

  const { isSubmittingOrder } = React.useContext(CheckoutContext);
  const formatMessage = useFormatMessage();

  return (
    <>
      <PaymentForm
        active={active}
        completed={completed}
        handleSubmit={handleSubmit}
        paymentGatewayType={AuroraPayPaymentInfoStage.Editable.GatewayType}
      >
        {formik => {
          return (
            <>
              <DecoratedField
                className={'mt-2'}
                required={true}
                disabled={isSubmittingOrder || !active}
                id="purchaseOrderNumber"
                name="purchaseOrderNumber"
                maxLength={20}
                label={formatMessage(messages.poNumberFieldLabel)}
                placeholder={formatMessage(messages2.purchaseOrderNumberLabel)}
                hint={formatMessage(messages2.purchaseOrderNumberHint)}
                validate={v => {
                  if (0 === v.length || v.length > 21) {
                    return formatMessage(messages.purchaseOrderValidationError);
                  }
                  return '';
                }}
              />
            </>
          );
        }}
      </PaymentForm>
    </>
  );
};

AuroraPayPaymentInfoEditable.GatewayType = PaymentGatewayType.AURORA_PAY;

const AuroraPayPaymentInfoReadOnly = () => {
  const formatMessage = useFormatMessage();
  const { isSubmittingOrder } = useContext(CheckoutContext);
  const history = useHistory();
  const { cart } = useContext(CartContext);
  return (
    <div className="w-full">
      <div>
        <div>
          <AuroraPayImage className={'h-12 p-4 rounded bg-aurora-700'} />
        </div>
        <div className="font-bold mb-2 mt-2">
          {formatMessage(messages.auroraPay)}
        </div>
        <p>Purchase Order: {cart.purchaseOrderNumber}</p>
      </div>
      <div className="flex justify-end w-full">
        <SecondaryButton
          onClick={() => {
            const state = get(history, 'location.state', {});
            history.push(PAYMENT_INFO_PATH, {
              ...state,
              editing: true
            });
          }}
          size={SecondaryButton.Size.SMALL}
          disabled={isSubmittingOrder}
        >
          {formatMessage(messages.edit)}
        </SecondaryButton>
      </div>
    </div>
  );
};

AuroraPayPaymentInfoStage.Editable = AuroraPayPaymentInfoEditable;
AuroraPayPaymentInfoStage.ReadOnly = AuroraPayPaymentInfoReadOnly;

export default AuroraPayPaymentInfoStage;
