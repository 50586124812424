import { useMemo } from 'react';
import { merge } from 'lodash';

import useFetchPageApi from '../../useFetchPageApi';
import useTenantService from './useTenantService.js';

/**
 * Hook to use to fetch fleet addresses.
 * Response is a page of fleet addresses
 * @param  {Object}  config - Object containing additional request config such as
 *     the request params or request method
 * @param  {Boolean} [sendImmediate=false] - Whether to send the request immediately upon
 *     using the hook. If false, then the caller is responsible for triggering
 *     the `sendCallback`. Defaults to `false`.
 * @return {{error: Boolean, exception: Object, loading: Boolean, response: Object, sendCallback: function}}
 */
function useReadFleetAddressesApi(config, sendImmediate = false) {
  const { baseUrl, fleetLocationPath } = useTenantService();

  const fetchUrl = baseUrl + fleetLocationPath;
  const finalConfig = useMemo(
    () => merge({}, { method: 'get' }, config),
    [config]
  );

  return useFetchPageApi(fetchUrl, finalConfig, sendImmediate, true);
}

export default useReadFleetAddressesApi;
