import React, { useContext, useEffect, useMemo } from 'react';
import { get, isEmpty, isEqual } from 'lodash';
import { Redirect } from 'react-router-dom';

import { Stage } from 'app/checkout/elements';
import {
  CartContext,
  CustomerContext,
  PaymentContext,
  TenantContext
} from 'app/common/contexts';
import { FulfillmentType, SelectedPaymentType } from 'app/common/constants';
import { useFormatMessage, useNationalSiteContext } from 'app/common/hooks';

import {
  PassthroughPaymentInfoStage,
  PayPalPaymentInfoStage
} from './components';
import messages from './PaymentInfo.messages';
import SelectPaymentButton from 'app/checkout/components/CheckoutLayout/components/PaymentInfo/components/SelectPaymentButton';
import environment from 'app/common/services/Environment/Environment';
import {
  FULFILLMENT_INFO_PATH,
  FULFILLMENT_OPTIONS_PATH,
  LOCATION_INFO_PATH,
  REVIEW_PATH
} from 'app/checkout/components/CheckoutLayout/CheckoutPaths';
import AuroraPayPaymentInfoStage from './components/AuroraPayPaymentInfoStage/index.js';
import {
  AuroraPayImage,
  PaypalPaymentImage
} from 'app/checkout/utils/PaymentMethodUtil.js';

/**
 * Render component for checkout's payment info stage. This includes billing and
 * payment method infos.
 */
const PaymentInfo = ({ active, stageNumber = 3 }) => {
  const { cart, resolving } = useContext(CartContext);
  const { dealerSelected } = useNationalSiteContext();
  const {
    auroraPayEnabled,
    paypalEnabled,
    payments,
    resolving: resolvingPayment
  } = useContext(PaymentContext);
  const { application } = useContext(TenantContext);
  const { customer } = useContext(CustomerContext);
  const isFleetCustomerAndFleetDealer = useMemo(() => {
    if (customer) {
      return (
        application.fleetPerformEnabled &&
        customer.attributes['fleetPerformEnabled']
      );
    }
    return false;
  }, [application, customer]);
  const fleetAddressIsSelected = useMemo(() => {
    const resolved = !resolving && cart !== undefined;
    if (!resolved) {
      return false;
    }
    const { address: fulfillmentAddress = {} } = get(
      cart,
      'fulfillmentGroups[0]',
      {}
    );
    return fulfillmentAddress.fleetAddress;
  }, [resolving, cart]);
  const showAuroraPayOption = useMemo(() => {
    return (
      auroraPayEnabled &&
      isFleetCustomerAndFleetDealer &&
      fleetAddressIsSelected
    );
  }, [auroraPayEnabled, isFleetCustomerAndFleetDealer, fleetAddressIsSelected]);
  const {
    address: fulfillmentAddress = {},
    fulfillmentOption = {},
    type: fulfillmentType,
    pricedFulfillmentOption = {}
  } = get(cart, 'fulfillmentGroups[0]', {});
  const resolved = !resolving && cart !== undefined;
  const isVirtualFulfillment = fulfillmentType === FulfillmentType.VIRTUAL;
  const [isPayInStore, setIsPayInStore] = React.useState(
    get(cart, 'attributes.PAY_IN_STORE', false)
  );
  const formatMessage = useFormatMessage();
  const activePayments = get(payments, 'content');
  const [selectedPaymentType, setSelectedPaymentType] = React.useState('');

  useEffect(() => {
    if (isEmpty(selectedPaymentType)) {
      if (isPayInStore) {
        setSelectedPaymentType(SelectedPaymentType.PAY_IN_STORE);
      } else {
        const gatewayType = get(activePayments, '[0].gatewayType');
        if (!isEmpty(gatewayType)) {
          setSelectedPaymentType(gatewayType);
        }
      }
    }
  }, [selectedPaymentType, isPayInStore, activePayments]);

  const needsPricedOption =
    isFulfillmentServiceEnabled() && isEmpty(pricedFulfillmentOption);
  if (active && resolved && !resolvingPayment) {
    if (!dealerSelected) {
      return <Redirect to={LOCATION_INFO_PATH} push />;
    }

    if (!isVirtualFulfillment && isEmpty(fulfillmentAddress)) {
      return <Redirect to={FULFILLMENT_INFO_PATH} push />;
    }
    if (!isEmpty(fulfillmentAddress) && needsPricedOption) {
      return <Redirect to={FULFILLMENT_OPTIONS_PATH} push />;
    }

    if (!paypalEnabled && !showAuroraPayOption) {
      return <Redirect to={REVIEW_PATH} push />;
    }
  }

  let completed = !isEmpty(activePayments);

  if (isPayInStore) {
    completed = isPayInStore;
  } else if (selectedPaymentType === SelectedPaymentType.PAYPAL_CHECKOUT_V2) {
    const paymentGatewayProperties = get(
      activePayments,
      '[0].paymentGatewayProperties',
      {}
    );
    completed = completed && !isEmpty(paymentGatewayProperties);
  } else {
    const hasAddress = !isEmpty(fulfillmentAddress);
    const hasFulfillmentOption =
      isVirtualFulfillment || !isEmpty(fulfillmentOption) || !needsPricedOption;
    completed = completed && hasAddress && hasFulfillmentOption;
  }

  const paypalSelected =
    !isPayInStore &&
    isEqual(selectedPaymentType, SelectedPaymentType.PAYPAL_CHECKOUT_V2);

  const auroraPaySelected =
    !isPayInStore &&
    isEqual(selectedPaymentType, SelectedPaymentType.AURORA_PAY);

  let PaymentInfoComponent = '';

  if (paypalSelected) {
    PaymentInfoComponent = PaymentInfo.PayPal;
  } else if (auroraPaySelected) {
    PaymentInfoComponent = AuroraPayPaymentInfoStage;
  } else if (isPayInStore) {
    PaymentInfoComponent = PaymentInfo.Passthrough;
  } else {
    PaymentInfoComponent = EmptyPaymentInfoComponent;
  }

  return PaymentInfoComponent === null ? null : (
    <Stage
      active={active}
      completed={completed}
      heading={formatMessage(messages.title)}
      number={isVirtualFulfillment ? stageNumber - 1 : stageNumber}
    >
      {active && (
        <>
          <h3 className="mb-4 text-gray-900 font-bold lg:text-lg">
            {formatMessage(messages.legend)}
          </h3>
          <div className="flex items-center mb-2 gap-2">
            <SelectPaymentButton
              className="aurora-payment-button mr-2 flex-1 h-14"
              isEnabled={showAuroraPayOption}
              isSelected={auroraPaySelected}
              onClickEffect={() => {
                setIsPayInStore(false);
                setSelectedPaymentType(SelectedPaymentType.AURORA_PAY);
              }}
              displayLabel={messages.auroraPay}
              selectedBackgroundColor={'bg-aurora-700'}
              unSelectedBackgroundColor={
                'bg-aurora-700 hover:bg-aurora-100 focus:bg-aurora-100 active:bg-aurora-100 disabled:bg-gray-200'
              }
            >
              <AuroraPayImage />
            </SelectPaymentButton>
            <SelectPaymentButton
              className="paypal-payment-button mr-2 flex-1 h-14"
              isEnabled={paypalEnabled}
              isSelected={paypalSelected}
              onClickEffect={() => {
                setIsPayInStore(false);
                setSelectedPaymentType(SelectedPaymentType.PAYPAL_CHECKOUT_V2);
              }}
              displayLabel={messages.paypal}
            >
              <PaypalPaymentImage />
            </SelectPaymentButton>
            <SelectPaymentButton
              className="flex-1 h-14"
              isSelected={isPayInStore}
              onClickEffect={() => {
                setIsPayInStore(true);
                setSelectedPaymentType(SelectedPaymentType.PAY_IN_STORE);
              }}
              displayLabel={messages.payInStore}
            />
          </div>
        </>
      )}
      {(isPayInStore || paypalSelected || auroraPaySelected) && <Divider />}
      <PaymentInfoComponent
        active={active}
        completed={completed}
        isPayInStore={isPayInStore}
      />
    </Stage>
  );
};

function Divider() {
  return <hr className="mt-4 mb-2 bg-gray-400 lg:mt-6 lg:mb-4" />;
}

function isFulfillmentServiceEnabled() {
  return environment.get('FULFILLMENT_SERVICE_ENABLED', 'true') === 'true';
}

const EmptyPaymentInfoComponent = () => <div />;

PaymentInfo.Passthrough = PassthroughPaymentInfoStage;
PaymentInfo.PayPal = PayPalPaymentInfoStage;

export default PaymentInfo;
export { PaymentInfo };
