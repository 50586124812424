/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { useMemo } from 'react';
import { merge } from 'lodash';

import useRestApi from '../useRestApi';
import DefaultPagingParams from './DefaultPagingParams';

/**
 * Hook that fetches a page of results. The same as `useRestApi` except that it
 * adds default pagination params to other params passed in.
 *
 * @param {String} fetchUrl - URL of the endpoint to hit including the category
 *     id: `catalog/categories/category_1/products`
 * @param {Object} config - optional object with request config including params
 *     to be merged with the default set. Example to get the previous page if on
 *     page 2:
 *
 *     ```js
 *     {
 *       params: {
 *       forward: false,
 *       offset: 10,
 *       size: 10
 *     }
 *     }
 *     ```
 *
 *     Example to include a sort by name ascending then SKU descending:
 *
 *     ```js
 *     {
 *       params: {
 *         sort: ['name,asc', 'sku,desc']
 *       }
 *     }
 *     ```
 *
 *     Example to include a filter by name ignoring case:
 *
 *     ```js
 *     {
 *       params: {
 *         cq: "name=eqic='green*'"
 *       }
 *     }
 *     ```
 * @param {Boolean} [sendImmediate=true] - Whether to send the request
 *     immediately upon using the hook. If false, then the caller is
 *     responsible for triggering the `sendCallback`.
 * @param {Boolean} [rejectOnError=false] - Whether to reject the "send"
 *     promise on error response. Either way, the error will be part of a
 *     separate dispatch event.
 *
 * @return {{error: Boolean, exception: {}, loading: Boolean, response: { content: [{}] }, sendCallback: Function}}
 *    The response and request state
 */
function useFetchPageApi(
  fetchUrl,
  config,
  sendImmediate = true,
  rejectOnError = false
) {
  const finalConfig = useMemo(
    () => merge({}, { params: DefaultPagingParams }, config),
    [config]
  );
  return useRestApi(fetchUrl, finalConfig, sendImmediate, rejectOnError);
}

export default useFetchPageApi;
