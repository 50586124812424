/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  receiptLabel: {
    id: 'Checkout.Review.receiptLabel',
    defaultMessage: 'Send receipt to'
  },
  editLabel: {
    id: 'Checkout.Review.editLabel',
    defaultMessage: 'Edit'
  },
  submitLabel: {
    id: 'Checkout.Review.submitLabel',
    defaultMessage: 'Submit Order'
  },
  itemConfigErrors: {
    id: 'Checkout.Review.itemConfigErrors',
    defaultMessage: 'Some of your items appear to be misconfigured.'
  },
  itemConfigErrorsAction: {
    id: 'Checkout.Review.itemConfigErrorsAction',
    defaultMessage:
      'Click here to return to the cart to fix them before submitting your order.'
  },
  generalError: {
    id: 'Checkout.Review.generalError',
    defaultMessage:
      'We encountered an error processing your order. Please check the information you provided and try again.'
  },
  paymentError: {
    id: 'Checkout.Review.paymentError',
    defaultMessage:
      'We encountered an error processing your payment. Please check the information you provided and try again. If this problem persists, please try another form of payment.'
  },
  invalidOffer: {
    id: 'Checkout.Review.invalidOffer',
    defaultMessage:
      'An offer applied to your cart is no longer valid. Please remove any offer codes from your cart and try again.'
  },
  invalidDealer: {
    id: 'Checkout.Review.invalidDealer',
    defaultMessage:
      'No dealer location selected. Please select a location in the 1st checkout step above.'
  },
  signInLabel: {
    id: 'Checkout.Review.signInLabel',
    defaultMessage: 'Sign in'
  },
  nonStockingError: {
    id: 'Checkout.Review.nonStockingError',
    defaultMessage:
      'We apologize for the inconvenience, but {quantity, plural, one {part} other {parts} } {parts} {quantity, plural, one {is} other {are} } no longer sold from this site {requiresRemoval,select, other{ } true { } false {and {quantity, plural, one {has} other {have} } been removed from your {isCart, select, true {cart} other {list}}}}. Please make changes as necessary.'
  },
  inventoryUnavailableTitle: {
    id: 'Checkout.Review.inventoryUnavailableTitle',
    defaultMessage: 'Some items may not be available immediately'
  },
  inventoryUnavailable: {
    id: 'Checkout.Review.inventoryUnavailable',
    defaultMessage:
      'Some of the items in your cart may no longer be immediately available at the selected location. It can take 2-5 days to restock them.{breakLine}' +
      'You can:{breakLineWide}' +
      '1) Contact the dealer {phoneNumber, select, NA { } other {at {phoneNumber} }}to confirm when the items will be available{breakLine}' +
      '2) Accept any delays and continue checking out{breakLine}' +
      '3) Modify your cart, then checkout again.{breakLine}{breakLine}' +
      'Below are the affected items:{breakLine}'
  },
  itemUnavailable: {
    id: 'Checkout.Review.itemUnavailable',
    defaultMessage: '{partNumber}'
  },
  checkoutAnyway: {
    id: 'Checkout.Review.checkoutAnyway',
    defaultMessage: 'Accept delays'
  },
  modifyCart: {
    id: 'Checkout.Review.modifyCart',
    defaultMessage: 'Modify your cart'
  },
  priceCheckReprice: {
    id: 'Checkout.Review.priceCheckReprice',
    defaultMessage:
      'Your cart pricing has been updated for specific parts to meet your FleetPerform pricing. Please review the updated prices at the top of the page and try again.'
  },
  priceCheckFail: {
    id: 'Checkout.Review.priceCheckFail',
    defaultMessage:
      'AuroraPay Fleet price check failed, but the cart was not able to get repriced. Please update the cart or reach out to customer service.'
  },
  priceCheckItemRepriced: {
    id: 'Checkout.Review.priceCheckItemRepriced',
    defaultMessage: 'Price updated for {partNumber}.'
  },
  priceCheckItemNotRepriced: {
    id: 'Checkout.Review.priceCheckItemNotRepriced',
    defaultMessage:
      'Invalid price for {partNumber}, but price could not be updated.'
  },
  creditCheckFail: {
    id: 'Checkout.Review.creditCheckFail',
    defaultMessage:
      'Order cannot be processed as you have exceeded your credit limit. Please contact your Fleet Manager to update accordingly.'
  }
});
