import React from 'react';
import { get, isEqual } from 'lodash';

import {
  determineDefaultCountry,
  determineDefaultStateProvinceRegion
} from 'app/checkout/utils/AddressUtils';
import {
  CartContext,
  LocaleContext,
  PaymentContext,
  TenantContext
} from 'app/common/contexts';

import useBillingAddress from '../useBillingAddress';
import { PaymentGatewayType } from 'app/common/constants';

/**
 * Generates the initial form values for the StripePaymentInfo and AuroraPay form.
 *
 * @param {string} defaultPaymentGatewayType - The type of payment gateway to
 *     fallback to if there is no previous billing info to use
 *
 * @return {{}}
 */
function useInitialFormValues(defaultPaymentGatewayType) {
  const { currentLocale } = React.useContext(LocaleContext);
  const { cart } = React.useContext(CartContext);
  const { payments } = React.useContext(PaymentContext);
  const countryDefault = determineDefaultCountry(currentLocale);
  const stateProvinceRegionDefault =
    determineDefaultStateProvinceRegion(countryDefault);
  const addressInfo = useBillingAddress(cart);
  const { application } = React.useContext(TenantContext);

  return React.useMemo(() => {
    const { useFulfillmentAddress, hasFulfillmentAddress } = addressInfo;
    const activePayment = get(payments, 'content[0]', []);
    const { gatewayType, type } = activePayment;

    const isSameGateway = isEqual(defaultPaymentGatewayType, gatewayType);

    const address = addressInfo.address || {};

    const fallbackType =
      defaultPaymentGatewayType === PaymentGatewayType.AURORA_PAY
        ? 'CORCENTRIC'
        : 'CREDIT_CARD';
    return {
      fullName: address.fullName || '',
      firstName: address.firstName || '',
      lastName: address.lastName || '',
      addressLine1: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      country: get(address, 'country') || countryDefault,
      stateProvinceRegion:
        address.stateProvinceRegion || stateProvinceRegionDefault,
      city: address.city || '',
      companyName: address.companyName || '',
      postalCode: address.postalCode || '',
      useDeliveryAddress: useFulfillmentAddress,
      hasDeliveryAddress: hasFulfillmentAddress,
      paymentType: (isSameGateway && type) || fallbackType,
      gatewayType: (isSameGateway && gatewayType) || defaultPaymentGatewayType,
      creditCardNumber: '',
      creditCardHolder: '',
      creditCardSecCode: '',
      creditCardExpMonth: '',
      creditCardExpYear: '',
      shouldSavePaymentToCustomer: false,
      paymentGatewayProperties:
        (isSameGateway && activePayment.paymentGatewayProperties) || {},
      attributes: (isSameGateway && activePayment.attributes) || {},
      purchaseOrderNumber: cart.purchaseOrderNumber || '',
      dealerLocation: application.identifierValue || ''
    };
  }, [
    addressInfo,
    countryDefault,
    stateProvinceRegionDefault,
    defaultPaymentGatewayType,
    payments,
    cart.purchaseOrderNumber,
    application
  ]);
}

export default useInitialFormValues;
