/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Checkout.PaymentInfo.Passthrough.Editable.cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'Checkout.PaymentInfo.Passthrough.Editable.next',
    defaultMessage: 'Next'
  },
  genericError: {
    id: 'Checkout.PaymentInfo.Passthrough.Editable.genericError',
    defaultMessage:
      'We encountered a problem processing your registration request. Please try again later.'
  },
  payInStore: {
    id: 'Checkout.PaymentInfo.Passthrough.Stage.pay-in-store',
    defaultMessage: 'Paying in store at'
  }
});
