import { useMemo } from 'react';
import { Environment } from 'app/common/services/index.js';

function useTenantService() {
  return useMemo(
    () => ({
      baseUrl: Environment.get('TENANT_BASE_URL', '/tenant'),
      fleetLocationPath: '/fleets/locations'
    }),
    []
  );
}

export default useTenantService;
