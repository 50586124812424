import React, { useContext, useEffect, useMemo, useState } from 'react';
import { areAddressesEquivalent } from '@broadleaf/payment-js';
import { concat, find, get, isEmpty, join, map } from 'lodash';

import { AddressForm, LoadingIcon } from 'app/common/components';
import {
  useReadCustomerAddressesApi,
  useReadFleetAddressesApi
} from 'app/common/hooks';

import { AddressFormLegend, UserAddresses } from './components';
import { CheckoutContext } from 'app/checkout/contexts';
import { setSelectedAddress } from 'app/checkout/contexts/reducers/checkoutReducer';
import { CustomerContext, TenantContext } from 'app/common/contexts/index.js';
import { Environment } from 'app/common/services';

const AuthenticatedAddressForm = props => {
  const { address, disabled, type } = props;
  const [customerAddresses, setCustomerAddresses] = useState(undefined);
  const [useSavedAddress, setUseSavedAddress] = useState(false);

  const [fleetAddresses, setFleetAddresses] = useState(undefined);
  const [fleetAddressAvailable, setFleetAddressAvailable] = useState(false);

  const [fetchingCustomerAddresses, setFetchingCustomerAddresses] =
    useState(false);
  const [fetchingFleetAddresses, setFetchingFleetAddresses] = useState(false);
  const [customerReady, setCustomerReady] = useState(false);

  const {
    checkoutDispatch,
    selectedAddress,
    showAddressErrors,
    isSubmittingOrder
  } = useContext(CheckoutContext);

  const { application } = useContext(TenantContext);
  const { customer } = useContext(CustomerContext);

  useEffect(() => {
    setUseSavedAddress(!showAddressErrors);
  }, [showAddressErrors]);

  const isFleetCustomerAndFleetDealer = useMemo(() => {
    if (customer) {
      return (
        auroraPayEnabled() &&
        application.fleetPerformEnabled &&
        customer.attributes['fleetPerformEnabled']
      );
    }
    return false;
  }, [application, customer]);

  const fleetAcctsParam = useMemo(() => {
    if (!customer) {
      return '';
    }
    setCustomerReady(true);
    const fleetAccts = get(customer, 'attributes.fleetAccounts');
    return join(fleetAccts, ',');
  }, [customer]);

  const { loading, sendCallback: sendReadAddressCallback } =
    useReadCustomerAddressesApi({}, false);

  const { loadingFleetAddresses, sendCallback: sendReadFleetAddressCallback } =
    useReadFleetAddressesApi(
      {
        params: {
          fleetAccounts: fleetAcctsParam
        }
      },
      false
    );

  function getAllWithPaging(setter, setHasContent, readAddressesCallback) {
    (async () => {
      let stop = false;
      let hasContent = false;
      let allContent = [];

      while (!stop) {
        const response = await readAddressesCallback().catch(err => {
          stop = true;
          console.log(err);
        });
        if (isEmpty(response)) {
          stop = true;
        }
        if (!stop) {
          const { last, empty, content = [] } = response;
          if (!empty) {
            allContent.push(...content);
          }
          hasContent = hasContent || !empty;
          stop = !!empty || !!last;
        }
      }
      setter(allContent);
      setHasContent(hasContent);
    })();
  }

  useEffect(() => {
    if (
      !isSubmittingOrder &&
      customerReady &&
      !loading &&
      !fetchingCustomerAddresses
    ) {
      setFetchingCustomerAddresses(true);
      getAllWithPaging(
        setCustomerAddresses,
        setUseSavedAddress,
        sendReadAddressCallback
      );
    }
  }, [
    isSubmittingOrder,
    customerReady,
    loading,
    fetchingCustomerAddresses,
    sendReadAddressCallback
  ]);

  useEffect(() => {
    if (
      !isSubmittingOrder &&
      customerReady &&
      isFleetCustomerAndFleetDealer &&
      !loadingFleetAddresses &&
      !fetchingFleetAddresses
    ) {
      setFetchingFleetAddresses(true);
      getAllWithPaging(
        setFleetAddresses,
        setFleetAddressAvailable,
        sendReadFleetAddressCallback
      );
    }
  }, [
    isSubmittingOrder,
    customerReady,
    isFleetCustomerAndFleetDealer,
    loadingFleetAddresses,
    fetchingFleetAddresses,
    sendReadFleetAddressCallback
  ]);

  const combinedAddresses = useMemo(() => {
    const cust = !!customerAddresses ? customerAddresses : [];
    const fleet = !!fleetAddresses
      ? transformToCustomerAddress(fleetAddresses)
      : [];

    return concat(cust, fleet);
  }, [customerAddresses, fleetAddresses]);

  function transformToCustomerAddress(fltAdrs) {
    return map(fltAdrs, fltAdr => {
      return {
        id: fltAdr.locationId,
        name: fltAdr.name,
        addressLine1: fltAdr.addressLine1,
        addressLine2: fltAdr.addressLine2,
        addressLine3: fltAdr.addressLine3,
        city: fltAdr.city,
        stateProvinceRegion: fltAdr.state,
        postalCode: fltAdr.postal,
        country: fltAdr.country,
        phonePrimary: {
          phoneNumber: fltAdr.phone || '000-000-0000'
        },
        fleetAddress: 'true',
        fleetAccount: fltAdr.accountNumber,
        fleetLocationId: fltAdr.locationId
      };
    });
  }

  useEffect(() => {
    if (isEmpty(combinedAddresses)) {
      return;
    }

    setUseSavedAddress(true);
    const selected = find(combinedAddresses, sa =>
      areAddressesEquivalent(sa, address)
    );

    if (!isEmpty(selected)) {
      setSelectedAddress(checkoutDispatch, selected);
    } else if (!isEmpty(address)) {
      setUseSavedAddress(false);
    } else {
      let defaultProp;
      if (type === AddressForm.Type.FULFILLMENT) {
        defaultProp = 'defaultShippingAddress';
      } else if (type === AddressForm.Type.BILLING) {
        defaultProp = 'defaultBillingAddress';
      }

      const defaultAddress = find(combinedAddresses, [defaultProp, true]);
      if (defaultAddress) {
        setSelectedAddress(checkoutDispatch, defaultAddress);
      } else {
        setSelectedAddress(checkoutDispatch, combinedAddresses[0]);
      }
    }
    // eslint-disable-next-line
  }, [address, combinedAddresses, type]);

  if (loading || !combinedAddresses) {
    return (
      <div className="text-center">
        <LoadingIcon />
      </div>
    );
  }

  return (
    <>
      {useSavedAddress && (
        <UserAddresses
          customerAddresses={combinedAddresses}
          selectedAddress={selectedAddress}
          setUseSavedAddress={setUseSavedAddress}
          type={type}
        />
      )}

      {!useSavedAddress && (
        <AddressForm
          type={type}
          disabled={disabled}
          legend={
            <AddressFormLegend
              customerAddresses={combinedAddresses}
              setUseSavedAddress={setUseSavedAddress}
              type={type}
            />
          }
        />
      )}
    </>
  );
};

function auroraPayEnabled() {
  return Environment.get('AURORA_PAY_ENABLED', 'false') === 'true';
}

export default AuthenticatedAddressForm;
export { AuthenticatedAddressForm };
