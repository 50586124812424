/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import classNames from 'classnames';
import { values } from 'lodash';
import PropTypes from 'prop-types';

/**
 * Generic primary button. Takes additional class names to add to the base ones
 * and any props that would normally be applied to a button or link.
 * Content like text and icons should be passed as children.
 */
const SelectedButton = ({
  children,
  className,
  backgroundColor = 'bg-primary-200',
  borderColor = 'border-primary-200 disabled:border-gray-200',
  borderStyle = 'border border-solid rounded',
  rounded = true,
  textColor = 'text-gray-800',
  ...props
}) => (
  <div
    className={classNames(
      'selected inline-flex items-center justify-center',
      'capitalize font-bold leading-none',
      'appearance-none text-center',
      'focus:outline-none disabled:cursor-not-allowed',
      'p-2 text-sm sm:p-3 lg:text-base',
      {
        [className]: !!className,
        [backgroundColor]: !!backgroundColor && !props.disabled,
        [borderColor]: !!borderColor,
        [borderStyle]: !!borderStyle,
        [textColor]: !!textColor,
        'disabled:bg-gray-400 disabled:border-gray-400': !!props.disabled,
        rounded
      }
    )}
    {...props}
  >
    {children}
  </div>
);

SelectedButton.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  textColor: PropTypes.string,
  size: PropTypes.oneOf(values(SelectedButton.Size))
};

export default SelectedButton;
export { SelectedButton };
