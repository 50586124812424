/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';
import { get, isEmpty } from 'lodash';

import { Icon, PayPalButton } from 'app/common/components';
import { PaymentGatewayType } from 'app/common/constants';
import { CartContext, PaymentContext } from 'app/common/contexts';
import {
  useCartInfo,
  useFormatMessage,
  useModifyCartRequest
} from 'app/common/hooks';

import messages from '../../PaymentInfo.messages';
import PaymentForm from '../PaymentForm';
import PaymentFormReadOnly from '../PaymentFormReadOnly';
import { getCartHeaders } from 'app/common/utils/RequestUtils';
import { getPaymentRequestValues } from 'app/common/hooks/payment/paymentUtil';

const PayPalPaymentInfoStage = ({ active = false, completed = false }) => {
  return active || !completed ? (
    <PayPalPaymentInfoEditable active={active} completed={completed} />
  ) : (
    <PayPalPaymentInfoReadOnly />
  );
};

const PayPalPaymentInfoEditable = ({ active = false, completed }) => {
  const formatMessage = useFormatMessage();
  const {
    cart: { checkoutError, id, version },
    setCart
  } = React.useContext(CartContext);
  const { paypalAccount } = React.useContext(PaymentContext);
  const { baseUrl } = useCartInfo().checkoutOperations;
  const { sendCallback: updateCartInfo } = useModifyCartRequest(
    `${baseUrl}/${id}`,
    null,
    false
  );
  const cartHeaders = getCartHeaders(version);
  const handleSubmit = async (values, actions, addlInfo) => {
    const { attributes, paymentType, gatewayType, paymentMethodProperties } =
      values;

    // To be utilized with upgrade so the transfer to a PaymentTransaction can be slotted in.
    const updatedCart = await updateCartInfo({
      method: 'patch',
      data: {
        attributes: { PAY_IN_STORE: false }
      },
      headers: cartHeaders
    });
    setCart(updatedCart);

    const { sendPaymentRequest, method } = addlInfo;
    const amounts = getPaymentRequestValues(updatedCart);

    const request = {
      method,
      attributes: {
        PAY_IN_STORE: false,
        cart: JSON.stringify(updatedCart),
        ...attributes
      },
      type: paymentType,
      gatewayType,
      paymentMethodProperties,
      ...amounts
    };
    return await sendPaymentRequest(request);
  };

  return (
    <>
      {!isEmpty(checkoutError) &&
        checkoutError.failureType === 'FAILED_PAYMENT_CONFIRMATION' && (
          <section className="flex items-center mt-4 px-2 py-1 text-sm text-red-600 leading-snug border border-solid border-red-200 bg-red-100 rounded md:px-4 md:py-2 lg:text-base lg:leading-normal">
            <Icon className="mr-2 md:mr-4" name="exclamation-circle" />
            <span id="general-order-error">
              {formatMessage(messages.confirmationError)}
            </span>
          </section>
        )}
      <PaymentForm
        active={active}
        completed={completed}
        handleSubmit={handleSubmit}
        paymentGatewayType={PayPalPaymentInfoStage.Editable.GatewayType}
      >
        {formik => {
          const attributes = get(formik.values, 'attributes', {});
          return (
            <div className="flex flex-col my-4 mx-auto w-4/5">
              <div className="lg:max-w-sm">
                <PayPalButton
                  handleApprove={async (data, actions) => {
                    const { orderID, payerID } = data;
                    const order = await actions.order.get();
                    const email = get(order, 'payer.email_address');
                    const gatewayProperties = {
                      ORDER_ID: orderID,
                      PAYER_ID: payerID,
                      PAYEE_MERCHANT_ID: paypalAccount
                    };

                    formik.setFieldValue(
                      'paymentMethodProperties',
                      gatewayProperties,
                      false
                    );
                    formik.setFieldValue('attributes', { email }, false);
                    formik.handleSubmit();
                  }}
                  showButton={active}
                  style={{ fundingicons: 'true' }}
                />
                {!isEmpty(attributes) && (
                  <PaymentFormReadOnly.Method
                    attributes={attributes}
                    type="paypal"
                  />
                )}
              </div>
            </div>
          );
        }}
      </PaymentForm>
    </>
  );
};

PayPalPaymentInfoEditable.GatewayType = PaymentGatewayType.PAYPAL_CHECKOUT_V2;

const PayPalPaymentInfoReadOnly = () => {
  const { cart } = React.useContext(CartContext);

  const attributes = get(cart, 'activePayments[0].attributes');

  return (
    <PaymentFormReadOnly paymentMethodAttributes={attributes} type="paypal" />
  );
};

PayPalPaymentInfoReadOnly.Method = PaymentFormReadOnly.Method;
PayPalPaymentInfoReadOnly.Billing = PaymentFormReadOnly.Billing;

PayPalPaymentInfoStage.Editable = PayPalPaymentInfoEditable;
PayPalPaymentInfoStage.ReadOnly = PayPalPaymentInfoReadOnly;

export default PayPalPaymentInfoStage;
