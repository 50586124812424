import { defineMessages } from 'react-intl';

export default defineMessages({
  cancel: {
    id: 'Checkout.PaymentInfo.AuroraPay.Editable.cancel',
    defaultMessage: 'Cancel'
  },
  submit: {
    id: 'Checkout.PaymentInfo.AuroraPay.Editable.next',
    defaultMessage: 'Next'
  },
  genericError: {
    id: 'Checkout.PaymentInfo.AuroraPay.Editable.genericError',
    defaultMessage:
      'We encountered a problem processing your registration request. Please try again later.'
  },
  auroraPay: {
    id: 'Checkout.PaymentInfo.AuroraPay.auroraPay',
    defaultMessage: 'Aurora Pay'
  },
  edit: {
    id: 'Checkout.PaymentInfo.AuroraPay.edit',
    defaultMessage: 'Edit'
  },
  poNumberFieldLabel: {
    id: 'Checkout.PaymentInfo.AuroraPay.poNumberFieldLabel',
    defaultMessage: 'Purchase Order:'
  },
  purchaseOrderValidationError: {
    id: 'Checkout.PaymentInfo.AuroraPay.purchaseOrderValidationError',
    defaultMessage: 'Invalid PO Number'
  }
});
