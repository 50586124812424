import React, { useContext, useMemo } from 'react';
import { get, isEmpty, map, toString } from 'lodash';

import { AddressForm, Button } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

import messages from '../../AuthenticatedAddressForm.messages';
import { UserAddress } from './components';
import { CheckoutContext } from 'app/checkout/contexts';
import { setSelectedAddress } from 'app/checkout/contexts/reducers/checkoutReducer';
import Select from 'react-select';

const UserAddresses = props => {
  const { customerAddresses, setUseSavedAddress, type } = props;
  const formatMessage = useFormatMessage();
  const { checkoutDispatch, selectedAddress } = useContext(CheckoutContext);

  const selectOptions = useMemo(() => {
    return map(customerAddresses, customerAddress =>
      createOption(customerAddress)
    );

    function createOption(address) {
      return {
        label: createLabel(address),
        value: address,
        id: get(address, 'id')
      };
    }

    function createLabel(customerAddress) {
      if (!customerAddress) {
        return '';
      }
      return (
        <>
          <div className={'font-bold'}>
            {toSegment(customerAddress.name)}
            {toFleetAcctSegment(customerAddress.fleetAccount)}
          </div>
          {toSegment(customerAddress.firstName)}
          {toSegment(customerAddress.lastName)}
          {toSegment(customerAddress.addressLine1)}
          {toCitySegment(customerAddress.city)}
          {toSegment(customerAddress.stateProvinceRegion)}
          {toSegment(customerAddress.postalCode)}
        </>
      );
    }

    function toSegment(field) {
      if (!field) {
        return '';
      }
      return `${toString(field)} `; // trailing space
    }

    function toCitySegment(field) {
      if (!field) {
        return '';
      }
      return `${toString(field)}, `; // trailing space
    }

    function toFleetAcctSegment(field) {
      if (!field) {
        return '';
      }
      return ` - ${toString(field)}`;
    }
  }, [customerAddresses]);

  return (
    <>
      <div className="mb-4 text-gray-900 font-bold lg:text-lg">
        {formatMessage(messages.chooseSaved, { type })}
      </div>

      <Select
        className={'address-select mb-4'}
        onChange={selection => {
          setSelectedAddress(checkoutDispatch, selection.value);
        }}
        value={selectOptions.find(
          option => option.id === get(selectedAddress, 'id')
        )}
        options={selectOptions}
        placeholder={formatMessage(messages.chooseAddress)}
      />

      {selectedAddress && (
        <UserAddress
          address={selectedAddress}
          addressName={get(selectedAddress, 'name')}
          isDefault={
            (type === AddressForm.Type.FULFILLMENT &&
              get(selectedAddress, 'defaultShippingAddress')) ||
            (type === AddressForm.Type.BILLING &&
              get(selectedAddress, 'defaultBillingAddress'))
          }
          isSelected={true}
        />
      )}

      <Button
        type="button"
        className="w-full mb-4 p-4 text-gray-900 bg-white text-left rounded shadow hover:bg-gray-200 focus:outline-none focus:shadow-outline"
        onClick={() => {
          setSelectedAddress(checkoutDispatch, undefined);
          setUseSavedAddress(false);
        }}
      >
        {formatMessage(messages.useDifferentAddress)}
      </Button>
    </>
  );
};

export default UserAddresses;
export { UserAddresses };
