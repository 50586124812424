import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

import { CheckoutContext } from 'app/checkout/contexts';
import { Icon, SecondaryButton } from 'app/common/components';
import {
  CommunicationPreferenceType,
  FulfillmentType
} from 'app/common/constants';
import { CartContext, TenantContext } from 'app/common/contexts';
import { useFormatMessage } from 'app/common/hooks';
import messages from './FulfillmentInfoWithOptions.messages';
import {
  getPaymentMethodLabel,
  getPaymentMethodLogo
} from 'app/checkout/utils/PaymentMethodUtil';
import {
  FULFILLMENT_INFO_PATH,
  goToCheckoutStep
} from 'app/checkout/components/CheckoutLayout';
import { FulfillmentAddressReadOnly } from './components';

/**
 * @param disabled {boolean} - Whether the form is disabled
 * @param history {object} - The history object from react-router
 * @param chooseDelivery {boolean} - Whether the user should be able to choose delivery options
 * @return {Element}
 * @constructor
 */
const FulfillmentInfoWithOptionsRead = ({ disabled = false, history }) => {
  const formatMessage = useFormatMessage();
  const { cart } = React.useContext(CartContext);
  const { isSubmittingOrder, selectedFulfillmentType } =
    React.useContext(CheckoutContext);
  const dealerName = get(
    React.useContext(TenantContext),
    'application.displayName'
  );
  const fulfillmentGroup = get(cart, 'fulfillmentGroups[0]', {});
  const orderInstructions = get(cart, 'attributes[ORDER_INSTRUCTIONS]');
  const communicationPreference = get(
    cart,
    'attributes[COMMUNICATION_PREF]',
    CommunicationPreferenceType.Phone.value
  );
  const paymentGatewayType = get(cart, 'paymentGatewayType');
  const purchaseOrderNumber = get(cart, 'purchaseOrderNumber');
  const { address = {} } = fulfillmentGroup;
  const goToFulfillmentEdit = goToCheckoutStep(history, FULFILLMENT_INFO_PATH, {
    editing: true
  });
  return (
    <div className="flex flex-col w-full">
      <aside className="flex items-center mb-4 px-2 py-1 text-sm text-blue-700 leading-snug border border-solid border-blue-300 bg-blue-100 rounded lg:-mt-1 lg:mr-11 md:px-4 md:py-2 lg:text-base lg:leading-normal">
        <Icon className="mr-2 md:mr-4" name="info-circle" />
        {/*Div is needed to prevent bolded text from getting flexed as a row*/}
        <div>
          <FormattedMessage
            {...messages.caveat}
            values={{ dealerName: <b>{dealerName}</b> }}
          />
        </div>
      </aside>
      <div className="flex">
        <section className="flex flex-col w-full mb-2 md:flex-row md:mb-0">
          <FulfillmentAddressReadOnly
            address={address}
            className="w-full text-sm md:w-1/2 md:pr-1"
            isPickup={selectedFulfillmentType === FulfillmentType.PICKUP}
            messages={messages}
          />
          <FulfillmentInfoReadOnlyWithOptionsMisc
            communicationPreference={communicationPreference}
            orderInstructions={orderInstructions}
            formatMessage={formatMessage}
            isPickup={selectedFulfillmentType === FulfillmentType.PICKUP}
            purchaseOrderNumber={purchaseOrderNumber}
            paymentGatewayType={paymentGatewayType}
          />
        </section>
        <footer className="flex justify-end items-end">
          <SecondaryButton
            onClick={goToFulfillmentEdit}
            size={SecondaryButton.Size.SMALL}
            disabled={disabled || isSubmittingOrder}
          >
            {formatMessage(messages.edit)}
          </SecondaryButton>
        </footer>
      </div>
    </div>
  );
};

const FulfillmentInfoReadOnlyWithOptionsMisc = ({
  communicationPreference,
  orderInstructions,
  isPickup = false,
  purchaseOrderNumber,
  paymentGatewayType,
  chooseDelivery
}) => {
  const formatMessage = useFormatMessage();
  return (
    <section className="w-full text-sm md:w-1/2">
      <FulfillmentInfoSection
        header={formatMessage(messages.orderInstructions)}
        condition={!isEmpty(orderInstructions)}
      >
        <p className="overflow-wrap">{orderInstructions}</p>
      </FulfillmentInfoSection>
      <FulfillmentInfoSection header={formatMessage(messages.deliveryMethod)}>
        <div>
          {isPickup
            ? formatMessage(messages.deliveryMethodPickup)
            : formatMessage(messages.deliveryMethodDeliver)}
        </div>
      </FulfillmentInfoSection>
      <FulfillmentInfoSection
        header={formatMessage(getPaymentMethodLabel())}
        condition={!isEmpty(paymentGatewayType)}
      >
        {getPaymentMethodLogo(paymentGatewayType, 'h-8', formatMessage)}
      </FulfillmentInfoSection>
      <FulfillmentInfoSection
        header={formatMessage(messages.communicationPreference)}
      >
        <div>
          {formatMessage(
            CommunicationPreferenceType[communicationPreference].label
          )}
        </div>
      </FulfillmentInfoSection>
      <FulfillmentInfoSection
        condition={!isEmpty(purchaseOrderNumber)}
        header={formatMessage(messages.purchaseOrderNumber)}
      >
        <div>{purchaseOrderNumber}</div>
      </FulfillmentInfoSection>
    </section>
  );
};

const FulfillmentInfoSection = props => {
  const header = props.header;
  const children = props.children;
  const noRender = props.condition === false;
  if (noRender) {
    return null;
  }
  return (
    <section className="mb-4">
      <h3 className="mt-2 mb-1 text-base text-gray-900 font-bold md:mt-0">
        {header}
      </h3>
      {children}
    </section>
  );
};

FulfillmentInfoWithOptionsRead.Method = () => null;

FulfillmentInfoWithOptionsRead.propTypes = {
  disabled: PropTypes.bool,
  Misc: PropTypes.elementType,
  chooseDelivery: PropTypes.bool
};

FulfillmentInfoSection.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node,
  condition: PropTypes.bool
};

const FulfillmentInfoWithOptionsReadOnly = withRouter(
  FulfillmentInfoWithOptionsRead
);
export {
  FulfillmentInfoWithOptionsReadOnly,
  FulfillmentInfoReadOnlyWithOptionsMisc
};
