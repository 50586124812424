import messages from 'app/my-account/components/Orders/components/OrderSummary/OrderSummary.messages';
import { useFormatMessage } from 'app/common/hooks';
import { get } from 'lodash';
import React from 'react';
import classNames from 'classnames';
import PaymentGatewayType from 'app/common/constants/PaymentGatewayType';

const PAYMENT_METHOD_LABEL_PREFIX = 'paymentGatewayType';
const PAY_IN_STORE = 'COD';
const PAYPAL = 'PAYPAL_CHECKOUT_V2';
const PAY_IN_STORE_LABEL = PAYMENT_METHOD_LABEL_PREFIX + PAY_IN_STORE;
const PAYPAL_LABEL = PAYMENT_METHOD_LABEL_PREFIX + PAYPAL;
const AURORA_PAY_LABEL = PAYMENT_METHOD_LABEL_PREFIX + 'AURORA_PAY';

const PaypalImage = () => {
  const formatMessage = useFormatMessage();
  const label = formatMessage(get(messages, PAYPAL_LABEL));
  return (
    <img
      alt={label}
      title={label}
      src={'/images/paypal_logo.png'}
      className={'h-5 pt-1'}
    />
  );
};

const PaypalPaymentImage = () => {
  const formatMessage = useFormatMessage();
  const label = formatMessage(get(messages, PAYPAL_LABEL));
  return (
    <img
      alt={label}
      title={label}
      src={'/images/paypal_logo.png'}
      className={'paypal-pay-logo max-h-full'}
    />
  );
};

const AuroraPayImage = ({ className }) => {
  const formatMessage = useFormatMessage();
  const label = formatMessage(get(messages, AURORA_PAY_LABEL));
  return (
    <img
      alt={label}
      title={label}
      src={'/images/aurora_pay_logo.png'}
      className={classNames('aurora-pay-logo ', className)}
    />
  );
};

const getPaymentMethodLogo = (gatewayType, className, formatMessage) => {
  if (gatewayType === PaymentGatewayType.AURORA_PAY) {
    return (
      <AuroraPayImage
        className={classNames('bg-aurora-700 rounded p-2', className)}
      />
    );
  }

  if (gatewayType === PaymentGatewayType.PAYPAL_CHECKOUT_V2) {
    return <PaypalImage />;
  }

  return formatMessage(getPaymentGatewayLabel(gatewayType));
};

const getPaymentGatewayLabel = paymentGatewayType => {
  if (paymentGatewayType === PAYPAL) {
    return get(messages, PAYPAL_LABEL);
  }
  if (paymentGatewayType === PaymentGatewayType.AURORA_PAY) {
    return get(messages, AURORA_PAY_LABEL);
  }
  return get(messages, PAY_IN_STORE_LABEL);
};

const getPaymentGatewayType = order => {
  const fallback = get(
    order,
    'internalAttributes.paymentGatewayType',
    PAY_IN_STORE
  );
  return get(order, 'paymentGatewayType', fallback);
};

const getPaymentMethodLabel = () => {
  return messages.paymentMethod;
};

const isPayInStore = paymentGatewayType => {
  return paymentGatewayType === PAY_IN_STORE;
};

export {
  PaypalImage,
  PaypalPaymentImage,
  AuroraPayImage,
  getPaymentMethodLogo,
  getPaymentGatewayType,
  getPaymentGatewayLabel,
  getPaymentMethodLabel,
  isPayInStore
};
