/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import { SecondaryButton } from 'app/common/components';
import { SelectedButton } from 'app/common/components';
import { useFormatMessage } from 'app/common/hooks';

/**
 * Helper component to simulate a radio field for selecting a payment type that uses implemented buttons.
 */
const SelectPaymentButton = ({
  className,
  isEnabled = true,
  isSelected,
  onClickEffect,
  displayLabel,
  selectedBackgroundColor,
  selectedBorderStyle,
  unSelectedBackgroundColor,
  children
}) => {
  const formatMessage = useFormatMessage();

  if (!isEnabled) {
    return <div className={'flex-1 order-last'}></div>;
  }

  if (isSelected) {
    return (
      <SelectedButton
        className={className}
        backgroundColor={selectedBackgroundColor}
        borderStyle={selectedBorderStyle}
      >
        {children !== undefined ? children : formatMessage(displayLabel)}
      </SelectedButton>
    );
  } else {
    return (
      <SecondaryButton
        className={className}
        onClick={onClickEffect}
        backgroundColor={unSelectedBackgroundColor}
      >
        {children !== undefined ? children : formatMessage(displayLabel)}
      </SecondaryButton>
    );
  }
};

export default SelectPaymentButton;
export { SelectPaymentButton };
